import { render, staticRenderFns } from "./Support.vue?vue&type=template&id=4ac42b38&scoped=true"
import script from "./Support.vue?vue&type=script&lang=js"
export * from "./Support.vue?vue&type=script&lang=js"
import style0 from "./Support.vue?vue&type=style&index=0&id=4ac42b38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac42b38",
  null
  
)

export default component.exports
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QNoSsr});
